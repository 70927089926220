.reviewsColumn{
    width: 890px;
    display: inline-block;
}
.reviewsColumnMobile{
    width: 95%;
}
.reviewBox{
    width: 90%;
    min-height: 80px;
}
.reviewComment{
    padding: 10px 5px;
}