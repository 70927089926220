.starRatingBox{
    width: 200px;
    height: 50px;
    background-color: rgb(221, 221, 221);
    position: relative;
    text-align: left;
    display: inline-block;
}
.imageOverlay{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    object-fit: contain;
}
.starRatingBackground{
    height: 100%;
    width: 50%;
    background-color: goldenrod;
    display: inline-block;
}
.starRatingBackgroundGrey{
    background-color: rgb(200, 200, 200);
}
.starRatingText{
    display: inline-block;
    height: 50px;
    line-height: 50px;
    vertical-align: top;
    padding-left: 10px;
    font-size: 20px;
}