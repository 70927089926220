
.bottomNav{
    border-top: 4px solid rgb(231, 174, 3);;
    background-color: black;
    padding: 0 50px;
    box-sizing: border-box;
    width: 100%;
    min-height: 300px;
    padding-top: 40px;
    padding-bottom: 20px;
}
.bottomNavColumn{
    padding: 0px 20px;
    box-sizing: border-box;
    width: 33%;
    color: white;
    display: inline-block;
    /* background-color: blue; */
    vertical-align: top;
}
.bottomNavColumn .logo{
    display: inline-block;
}
.bottomNavColumnTitle{
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    padding-bottom: 10px;    
}
.bottomNavColumnLinks a{
    color: white;
    text-decoration: none;
    display: block;
    padding: 8px;
    transition: 500ms;
}
.bottomNavColumnLinks a:hover{
    background-color: rgba(145, 145, 145, 0.37);
}
@media screen and (max-width: 900px){
    .topNavButtons{
        display: none;
    }
    .topNavButtonsMenu{
        display: inline-block;
    }
    .topNavButtonsMenuButtons{
        display: inline-block;
    }
    .bottomNavColumn{
        width: 50%;
        padding-top: 10px;
    }
    .bottomNavLogoColumn{
        width: 100%;
    }
    .bottomNavLogoColumn img{
        width: 100%;
        height: 100px;
    }
    .bottomNavColumnTitle{
        padding-top: 20px;
    }
}
@media screen and (max-width: 650px){
    .bottomNav{
        padding-left: 0;
        padding-right: 0;
    }
}