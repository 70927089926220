.companyOverviewTitle{
    margin-top: 20px;
}
.sectionButton{
    display: inline-block;
    padding: 0 10px 10px 10px;
    cursor: pointer;
    user-select: none;
}
.sectionButtonSelected{
    border-bottom: 2px solid goldenrod;
}
.copmanyOverviewButtons{
    width: 800px;
    border-bottom: 1px solid rgb(180, 180, 180);
    margin-bottom: 20px;
}
.companyOverviewSectionDisplay{
    /* max-width: 1000px; */
    width: 100%;
    display: inline-block;
    margin-bottom: 20px;
}