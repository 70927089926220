/* Banner New */

.banner{
    background-position: center;
    background-size: cover;
    position: relative;
    min-height: 400px;
}
.bannerImg{
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    object-fit: cover;
    z-index: -1;
    object-position: center;
    background-color: rgb(199, 199, 199);
    object-position: right;
}
.bannerImgMobile{
    object-position: center;
    text-align: center;
    /* object-position: -1000px; */
}
.bannerTextSection{
    width: 60%;
    max-width: 600px;
    margin-left: 120px;
    padding-top: 60px;
    color: white;
    text-align: left;
    font-weight: bold;
    padding-bottom: 20px;
    border-radius: 5px;
}
.bannerTextSectionMobile{
    margin-left: 0px;
    text-align: center;
    width: 100%;
    padding: 0px 10px;
    padding-top: 40px;
    display: inline-block;
}
.bannerTitle{
    font-size: 40px;
    display: block;
    margin-bottom: 20px;
}
.bannerTitleMobile{
    /* font-size: 30px; */
}
.bannerDescription{
    font-size: 20px;
    margin-bottom: 20px;
}

/* Image Text Section */

.textSection{
    /* width: calc(100% - 340px); */
    vertical-align: top;
    padding: 20px;
}

/* Applicatoin Sectoin 2 */
.applicationSection2{
    padding: 30px 10px;
    background-image: url("../../Images/greyBackground.webp");
    background-size: cover;
    box-sizing: content-box;
}
.applicationSection2 img{
    height: 400px;
    width: 400px;
    display: inline-block;
    vertical-align: top;
}
.applicationSection2Text{
    width: 530px;
    /* width: (100% - 400px); */
    display: inline-block;
    vertical-align: top;
    text-align: left;
    color: white;
    margin-left: 40px;
    margin-top: 80px;
    text-align: center;
}
.applicationSection2TextMobile{
    margin: 0;
    padding: 5px;
    width: 100%;
    max-width: 100%;
}
.applicationSection2Title{
    font-size: 31px;
    font-weight: bold;
    border-bottom: 4px solid #fc0;
}
.applicationSection2Title2{
    font-size: 25px;
    font-weight: 500;
    padding-top: 40px;
}
.applicationSection2Title3{
    font-size: 16px;
    font-weight: 500;
    padding-top: 10px;
}
.applicationSection2ButtonConteiner{
    padding-top: 40px;
}

/* Color Box */
.colorBox{
    background-color: #fc0;
    padding: 40px;
}

/* circkeButton */
.circkeButtonContainer{
    width: 100%;
    display: flex;
    justify-content: space-between;
    
}
.circleButton{
    display: inline-block;
    margin: 10px 20px;
}
.circleButtonImg{
    width: 160px;
    height: 160px;
    border-radius: 50%;
    overflow: hidden;
    background-color:  white;
    transition: 200ms;
    border: 4px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
}
.circleButtonImg img{
    width: 60%;
    height: 60%;
}
.circleButtonImg:hover{
    border: 4px solid rgb(255, 162, 0);
}


/* Media Queries */
@media screen and (max-width: 750px) {
    .programBannerTitle{
        font-size: 20px;
    }
    .colorBox{
        padding: 10px
    }
    .circleButton{
        width: 40%;
    }
}

@media screen and (min-width: 1250px) {
    .programBannerText{
        margin-left: 10%;

    }

}


